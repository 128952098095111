/* src/styles/GetStarted.css */

.get-started {
    background-color: black; /* Black background */
    padding: 60px 20px;
  }
  
  .get-started-content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding-right: 3.5vw;
  }
  
  .get-started h2 {
    color: #ffffff; /* White text */
    font-size: 38px;
    margin: 0;
    flex: 1;
  }
  
  .get-started button {
    background-color: #ffcc00; /* Yellow button */
    color: #1a1a1a; /* Dark text */
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    margin-left: 20px;
    transition: background-color 0.3s ease;
  }
  
  .get-started button:hover {
    background-color: #e6b800; /* Slightly darker yellow on hover */
  }
  
  @media (max-width: 768px) {
    .get-started-content {
      flex-direction: column;
      text-align: center;
    }
  
    .get-started h2 {
      margin-bottom: 20px;
    }
  
    .get-started button {
      margin-left: 0;
    }
  }
  