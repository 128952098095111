/* styles/Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a; /* Dark background */
  position: relative;
  z-index: 100;
}

.nav-brand a {
  display: flex;
  align-items: center;
}

.nav-logo {
  margin-left: 4vw;
  max-height: 150px; 
}

.nav-logo img {
  height: auto;
  width: auto;
  max-height: 100%;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin-right: 5vw;
  position: relative;
}

.nav-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 22px;
  padding: 5px 10px;
}

.nav-links a:hover,
.about-link:hover,
.services-link:hover {
  color: #ffcc00; /* Yellow text */
  background-color: #333333; /* Dark grey background */
  border-radius: 4px;
}

/* About Us Dropdown Styles */
.about-menu {
  position: relative;
}

.about-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 22px;
  cursor: pointer;
  padding: 5px 10px;
}

.about-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333333; /* Dark grey background */
  padding: 10px 0;
  list-style: none;
  border-radius: 4px;
  /* Removed border */
  /* border: 1px solid #ffcc00; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  min-width: 160px;
}

.about-dropdown li {
  width: 100%; /* Span full width */
}

.about-dropdown li a {
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  display: block;
  width: 100%; /* Span full width */
}

.about-dropdown li a:hover {
  background-color: #444444; /* Slightly lighter grey */
  color: #ffcc00;
}

/* Services Dropdown Styles */
.services-menu {
  position: relative;
}

.services-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 22px;
  cursor: pointer;
  padding: 5px 10px;
}

.services-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333333; /* Dark grey background */
  padding: 10px 0;
  list-style: none;
  border-radius: 4px;
  /* Removed border */
  /* border: 1px solid #ffcc00; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  min-width: 160px;
}

.services-dropdown li {
  width: 100%; /* Span full width */
}

.services-dropdown li a {
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  display: block;
  width: 100%; /* Span full width */
}

.services-dropdown li a:hover {
  background-color: #444444; /* Slightly lighter grey */
  color: #ffcc00;
}

/* Dropdown Menu (for user menu) */
.menu-button {
  background-color: #ffcc00;
  color: #1a1a1a;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.menu-button:hover {
  background-color: #e6b800;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #333333; /* Dark grey background */
  /* Removed border */
  /* border: 1px solid #ffcc00; */
  border-radius: 4px;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  opacity: 0;
  animation: dropdown 0.3s forwards;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  min-width: 160px;
}

.dropdown-menu li {
  padding: 8px 16px;
  width: 100%; /* Span full width */
}

.dropdown-menu li:hover {
  background-color: #444444; /* Slightly lighter grey */
}

.dropdown-menu li a,
.dropdown-menu li button {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  display: block;
  width: 100%; /* Span full width */
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu li button:hover {
  background: none;
}

.dropdown-menu li a:hover {
  color: #ffcc00;
}

/* Mobile Styles */
.mobile-menu-icon {
  display: none;
  font-size: 28px;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #1a1a1a;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
    width: 100%;
  }

  .nav-links a {
    font-size: 18px;
  }

  .mobile-menu-icon {
    display: block;
  }

  .nav-logo {
    width: 40vw;
    max-height: none; /* Allow larger logo */
  }

  /* Dropdowns in mobile */
  .nav-links .about-dropdown.mobile,
  .nav-links .services-dropdown.mobile {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    background-color: #2a2a2a;
    width: 100%;
    padding: 0;
    box-shadow: none;
  }

  .nav-links .about-dropdown.mobile li,
  .nav-links .services-dropdown.mobile li {
    padding: 10px 20px;
    width: 100%; /* Span full width */
  }

  .nav-links .about-dropdown.mobile li a,
  .nav-links .services-dropdown.mobile li a {
    color: #ffffff;
    width: 100%; /* Span full width */
  }

  .nav-links .about-dropdown.mobile li a:hover,
  .nav-links .services-dropdown.mobile li a:hover {
    color: #ffcc00;
  }

  /* Hide dropdown arrows */
  .nav-links .about-link::after,
  .nav-links .services-link::after {
    content: '';
  }
}
