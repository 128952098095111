/* src/styles/ResidentialServices.css */

/* General Styles */
.residential-page {
  font-family: Arial, sans-serif;
  color: #fff;
  background-color: #2d2d2d;
}

section {
  padding: 60px 20px;
}

h2 {
  color: #ffcc00;
  margin-bottom: 20px;
  text-align: center;
}

h3 {
  color: #ffcc00;
  margin-bottom: 15px;
}

p {
  font-size: 1.1em;
  line-height: 1.8;
}

/* Header Section */
.residential-header {
  background-image: url('../assets/layingshingles.jpg'); /* Updated image path */
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 100px 20px;
  text-align: center;
  position: relative;
}

.residential-header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 45, 45, 0.7); /* Overlay to enhance text readability */
}

.residential-header .header-text {
  position: relative;
  z-index: 1;
}

.residential-header h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.residential-header h2 {
  font-size: 1.5em;
  font-weight: normal;
}

/* Introduction Section */
.residential-intro {
  background-color: #1a1a1a;
}

.intro-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.intro-content p {
  font-size: 1.1em;
  line-height: 1.8;
}

.intro-list {
  list-style: none; /* Remove default bullets */
  margin: 20px auto;
  padding: 0;
  max-width: 600px;
  text-align: left;
}

.intro-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
  list-style: none; /* Ensure default bullets are removed */
}

.intro-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  width: 10px;
  height: 10px;
  background-color: #ffcc00;
  border-radius: 50%;
}

/* Services Section */
.residential-services {
  background-color: #2d2d2d;
}

.services-content {
  max-width: 800px;
  margin: 0 auto;
}

.services-content h2 {
  text-align: center;
}

.services-list {
  max-width: 800px;
  margin: 0 auto;
}

.service-item {
  border-bottom: 1px solid #444;
}

.service-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  cursor: pointer;
}

.service-header h3 {
  margin: 0;
  color: #ffcc00;
}

.service-header span {
  font-size: 1.5em;
  color: #ffcc00;
}

.service-details {
  padding: 0 0 15px 0;
}

.service-details p {
  color: #ccc;
}

.service-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ffcc00;
  color: #1a1a1a;
  text-decoration: none;
  border-radius: 5px;
}

.service-button:hover {
  background-color: #e6b800;
}

/* CTA Section */
.residential-cta {
  background-color: #ffcc00;
  color: #1a1a1a;
  text-align: center;
}

.residential-cta .cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.residential-cta h2 {
  color: #1a1a1a;
}

.residential-cta p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #2d2d2d;
  color: #ffcc00;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: #333;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .residential-header h1 {
    font-size: 2em;
  }

  .residential-header h2 {
    font-size: 1.2em;
  }

  .intro-content p,
  .service-details p,
  .residential-cta p {
    font-size: 1em;
  }

  .service-header h3 {
    font-size: 1.3em;
  }

  section {
    padding: 40px 15px;
  }
}
