/* src/styles/LoginPage.css */

.login-page {
    background-color: #1a1a1a; /* Dark background */
    color: #ffffff; /* White text */
    min-height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .login-container {
    background-color: #2a2a2a; /* Slightly lighter background */
    padding: 40px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .login-container h2 {
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;
  }
  
  .login-description {
    margin-bottom: 30px;
    font-size: 16px;
    text-align: center;
    color: #cccccc;
  }
  
  .login-description a {
    color: #ffcc00;
    text-decoration: none;
  }
  
  .login-description a:hover {
    text-decoration: underline;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    background-color: #ffffff;
    color: #1a1a1a;
  }
  
  .input-group input::placeholder {
    color: #999999;
  }
  
  .input-group input:focus {
    outline: none;
    border: 2px solid #ffcc00; /* Yellow border on focus */
  }
  
  .login-button {
    width: 100%;
    padding: 15px;
    background-color: #ffcc00; /* Yellow background */
    color: #1a1a1a; /* Dark text */
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .login-button:hover {
    background-color: #e6b800; /* Slightly darker yellow on hover */
  }
  
  .error-messages {
    margin-bottom: 20px;
    background-color: #ff4d4d; /* Red background */
    padding: 10px;
    border-radius: 4px;
  }
  
  .error-messages p {
    margin: 0;
    color: #ffffff;
    font-size: 14px;
  }
  
  @media (max-width: 480px) {
    .login-container {
      padding: 20px;
    }
  
    .login-container h2 {
      font-size: 24px;
    }
  
    .login-description {
      font-size: 14px;
    }
  }
  