/* src/styles/ServicesSection.css */

.services-section {
  padding: 60px 20px;
  background-color: #1a1a1a; /* Darker background color */
  color: white;
}

.services-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffcc00;
}

.services-description {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 40px;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.services-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.service-box {
  background-color: #2d2d2d; /* Lighter background color for boxes */
  border: 1px solid #ffcc00;
  border-radius: 10px;
  flex: 1 1 300px;
  max-width: 350px;
  min-width: 280px;
  padding: 30px 20px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.service-box:hover {
  transform: translateY(-5px); /* Less movement */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

.service-box h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #ffcc00;
}

.service-box p {
  font-size: 1em;
  margin-bottom: 30px;
  color: white;
}

.service-link {
  display: inline-block;
  padding: 12px 25px;
  background-color: #ffcc00;
  color: #1a1a1a;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.service-link:hover {
  background-color: #e6b800;
}

@media screen and (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-box {
    max-width: 100%;
  }
}
