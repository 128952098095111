/* src/styles/NotFound.css */

.not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1a1a1a; /* Black background */
    color: #ffffff; /* White text */
    overflow: hidden;
    position: relative;
  }
  
  .not-found-content {
    text-align: center;
    z-index: 2;
  }
  
  .not-found h1 {
    font-size: 200px;
    margin: 0;
    line-height: 1;
    color: rgba(255, 255, 255, 0.1);
  }
  
  .not-found h2 {
    font-size: 48px;
    margin: 20px 0;
  }
  
  .not-found p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .not-found button {
    background-color: #ffcc00; /* Yellow button */
    color: #1a1a1a; /* Dark text */
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .not-found button:hover {
    background-color: #e6b800; /* Slightly darker yellow on hover */
  }
  
  /* Background animation */
  .not-found::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(#ffcc00 20%, transparent 20%) center center / 50px 50px;
    animation: rotate 10s linear infinite;
    opacity: 0.05;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg) translate(-25%, -25%);
    }
    to {
      transform: rotate(360deg) translate(-25%, -25%);
    }
  }
  
  @media (max-width: 768px) {
    .not-found h1 {
      font-size: 120px;
    }
  
    .not-found h2 {
      font-size: 32px;
    }
  
    .not-found p {
      font-size: 16px;
    }
  
    .not-found button {
      font-size: 16px;
      padding: 12px 25px;
      border-radius: 10px;
    }
  }
  