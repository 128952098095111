/* src/styles/AboutUs.css */

/* General Styles */
.aboutus-page {
  font-family: Arial, sans-serif;
  background: url('../assets/serviceareamap.png') no-repeat center center fixed;
  background-size: cover;
  color: #fff;
}

.all-content {
  background-color: rgba(26, 26, 26, 0.7);
  margin: 5vw;
  border-radius: 15px;
  padding: 20px; /* Added padding to ensure content doesn't touch edges */
}

/* Hero Section */
.aboutus-hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh; /* Reduced margin */
  margin-bottom: 5vh;
}

.aboutus-hero-text {
  text-align: center;
}

.aboutus-hero-title {
  font-size: 2.5em;
  color: #ffcc00; /* Dark yellow */
  margin-bottom: 10px;
}

.aboutus-hero-tagline {
  font-size: 1.2em;
  font-style: italic;
  color: #fff;
}

/* Middle Section */
.aboutus-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutus-carousel {
  position: relative;
  width: 80%; /* Increased width */
  max-width: 800px; /* Added max-width */
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.aboutus-card {
  flex: 0 0 100%;
  box-sizing: border-box;
}

.aboutus-content {
  background-color: rgba(45, 45, 45, 0.9); /* Semi-transparent background */
  border: 1px solid #ffcc00;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px;
  text-align: center;
}

.aboutus-title {
  font-size: 2em;
  color: #ffcc00; /* Dark yellow */
  margin-bottom: 20px;
}

.aboutus-text {
  font-size: 1em;
  line-height: 1.6;
  color: #fff;
  margin: 0 auto; /* Center the text */
  max-width: 600px; /* Limit the width of paragraphs */
}

.aboutus-list {
  list-style-type: none;
  padding: 0;
}

.aboutus-list li {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #fff;
  text-align: left;
  position: relative;
  padding-left: 25px;
}

.aboutus-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  background-color: #ffcc00;
  border-radius: 50%;
}

.aboutus-list li strong {
  color: #ffcc00;
}

/* Navigation Buttons */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(26, 26, 26, 0.7);
  border: none;
  color: #ffcc00;
  font-size: 2em;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 20px;
}

.carousel-button.next {
  right: 20px;
}

.carousel-button:hover {
  background-color: rgba(26, 26, 26, 0.9);
}

/* Contact Section */
.aboutus-contact {
  color: #fff; /* White text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh; /* Reduced padding */
  padding-bottom: 5vh;
}

.aboutus-contact .aboutus-container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.aboutus-contact .aboutus-title {
  color: #ffcc00;
}

.aboutus-contact-info {
  font-size: 1em;
  margin-bottom: 10px;
}

.aboutus-contact-info a {
  color: #ffcc00;
  text-decoration: none;
}

.aboutus-contact-info a:hover {
  text-decoration: underline;
}

.aboutus-contact .aboutus-text {
  max-width: 600px;
  margin: 0 auto;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .aboutus-hero-title {
    font-size: 2em;
  }

  .aboutus-hero-tagline {
    font-size: 1em;
  }

  .aboutus-title {
    font-size: 1.5em;
  }

  .aboutus-text,
  .aboutus-list li,
  .aboutus-contact-info {
    font-size: 1em;
  }

  .carousel-button {
    font-size: 1.5em;
    padding: 8px 12px;
  }

  .aboutus-content {
    padding: 15px;
  }

  .aboutus-carousel {
    width: 90%; /* Increased width on mobile */
  }

  .all-content {
    margin: 2vw;
    padding: 10px;
  }

  .aboutus-contact {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}
