/* src/styles/global.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    width: 100%;
    height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  