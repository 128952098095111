/* src/styles/ProcessSection.css */

.process-section {
  background-color: #1a1a1a;
  padding: 60px 20px;
}

.process-section h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 20px;
  color: white;
}

.title-add {
  text-align: center;
  font-size: 18px;
  margin-bottom: 50px;
  color: white;
}

.process-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process-step {
  display: flex;
  align-items: flex-start;
  width: 80%;
  margin-bottom: 40px;
  position: relative;
}

.process-step::before {
  content: '';
  position: absolute;
  left: 35px;
  top: 0;
  bottom: -40px;
  width: 2px;
  background-color: #ccc;
}

.process-step:last-child::before {
  bottom: auto;
  height: calc(100% - 40px);
}

.step-icon {
  position: relative;
  z-index: 1;
  background-color: #ffcc00;
  color: #2a2a2a;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-right: 30px;
}

.step-content {
  background-color: #2a2a2a;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.step-content h3 {
  margin-top: 0;
  font-size: 24px;
  color: white;
}

.step-content .step-number {
  color: #ffcc00;
  margin-right: 10px;
}

.step-content p {
  font-size: 16px;
  line-height: 1.6;
  color: white;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .process-step {
    flex-direction: column;
    align-items: center;
  }

  /* Hide the line on mobile */
  .process-step::before {
    display: none;
  }

  .step-icon {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .step-content {
    width: 100%;
    text-align: center;
  }
}
