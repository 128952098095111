/* styles/Footer.css */

.footer {
  background-color: black;
  color: #ffffff;
  padding: 4vh 0;
  margin-top: auto;
  font-size: larger;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Footer Top */
.footer-top {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
}

.footer-column {
  width: 30%;
  text-align: center; /* Center the content in each column */
}

.footer-column h3 {
  margin-bottom: 15px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #ffffff;
}

.service-area-map img {
  max-width: 100%;
  height: auto;
  width: 50%; /* Reduce the size to approximately half */
}

/* Contact Info */
.contact-info p {
  margin-bottom: 10px;
}

.contact-info a {
  color: #ffffff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Divider Line */
.footer-divider {
  border-top: 1px solid #ffffff;
  width: 90%;
  margin: 20px auto;
}

/* Footer Bottom */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}

.footer-left {
  margin: 0;
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer-right p {
  margin: 0;
  margin-right: 10px;
}

.footer-right a {
  margin-left: 10px;
}

.footer-right a img {
  width: 32px;
  height: 32px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-top,
  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .service-area-map img {
    width: 70%; /* Adjust the size for mobile view if needed */
  }

  .footer-bottom {
    padding: 0;
  }

  .footer-right {
    margin-top: 10px;
  }

  .footer-right p {
    margin-bottom: 10px;
  }
}
