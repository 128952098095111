/* src/styles/ContactForm.css */

/* Common styles for the contact form */
.contact-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  color: black;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Styles when inside the Hero component */
.contact-form {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10vw;
  width: 35vw;
}

/* Styles when not inside the Hero component */
.contact-page-wrapper {
  background-color: #2d2d2d;
  min-height: calc(100vh - 80px); /* Adjust for navbar height if necessary */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px; /* Added padding for top and bottom margins */
}

.contact-page-wrapper .contact-form {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  color: black;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

/* Form fields and buttons */
.contact-form h1 {
  margin-bottom: 20px;
  text-align: center;
}

.contact-form form > div {
  margin-bottom: 15px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #555;
  opacity: 1;
  font-weight: 500;
}

.contact-form button {
  display: block;
  margin: 20px auto 0;
  background-color: #ffcc00;
  color: #1a1a1a;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.contact-form button:hover {
  background-color: #e6b800;
}

/* Styles for the thank-you message */
.thank-you-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.thank-you-message h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.thank-you-message p {
  font-size: 18px;
  text-align: center;
}

/* Media query for tablets and below */
@media screen and (max-width: 768px) {
  /* Adjustments when inside the Hero component */
  .contact-form {
    margin-left: 0;
    width: 90%;
    min-height: auto;
    padding: 20px;
  }

  /* Adjustments when not inside the Hero component */
  .contact-page-wrapper .contact-form {
    width: 90%;
    padding: 20px;
  }

  .contact-form h1 {
    font-size: 24px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 16px;
  }

  .contact-form button {
    font-size: 16px;
  }

  .thank-you-message h1 {
    font-size: 28px;
  }

  .thank-you-message p {
    font-size: 16px;
  }
}
