/* src/styles/SolarServices.css */

/* General Styles */
.solar-page {
  font-family: Arial, sans-serif;
  color: #fff;
  background-color: #1a1a1a;
}

section {
  padding: 60px 20px;
}

h2 {
  color: #ffcc00;
  margin-bottom: 20px;
  text-align: center;
}

h3 {
  color: #ffcc00;
  margin-bottom: 15px;
}

p {
  font-size: 1.1em;
  line-height: 1.8;
}

/* Header Section */
.solar-header {
  background-image: url('../assets/solarpanels.jpg'); /* Update with your solar header image */
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 120px 20px;
  text-align: center;
  position: relative;
}

.solar-header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.6); /* Overlay for text readability */
}

.solar-header .header-text {
  position: relative;
  z-index: 1;
}

.solar-header h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.solar-header h2 {
  font-size: 1.5em;
  font-weight: normal;
}

/* Introduction Section */
.solar-intro {
  background-color: #2d2d2d;
}

.intro-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.intro-content p {
  font-size: 1.1em;
  line-height: 1.8;
  color: #ccc;
}

/* Solar Content Section */
.solar-content {
  background-color: #1a1a1a;
}

.content-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  align-items: flex-start;
  gap: 40px; /* Added gap between the flex items */
}

.solar-components {
  flex: 1;
}

.components-list {
  max-width: 100%;
}

.component-item {
  border-bottom: 1px solid #444;
}

.component-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  cursor: pointer;
}

.component-header h3 {
  margin: 0;
  color: #ffcc00;
}

.component-header span {
  font-size: 1.5em;
  color: #ffcc00;
}

.component-details {
  padding: 0 0 15px 0;
}

.component-details p {
  color: #ccc;
}

/* Video Section */
.solar-video {
  width: 50%;
  max-width: 560px;
}

.video-content {
  text-align: center;
}

.video-content h2 {
  margin-bottom: 20px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* CTA Section */
.solar-cta {
  background-color: #ffcc00;
  color: #1a1a1a;
  text-align: center;
}

.solar-cta .cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.solar-cta h2 {
  color: #1a1a1a;
}

.solar-cta p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #1a1a1a;
  color: #ffcc00;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: #333;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .content-container {
    flex-direction: column;
  }

  .solar-components {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .solar-video {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .solar-header h1 {
    font-size: 2em;
  }

  .solar-header h2 {
    font-size: 1.2em;
  }

  .intro-content p,
  .component-details p,
  .solar-cta p {
    font-size: 1em;
  }

  .component-header h3 {
    font-size: 1.3em;
  }

  section {
    padding: 40px 15px;
  }
}
