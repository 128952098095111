/* src/styles/HeroSection.css */

.hero-section {
  background-image: url('../assets/herosectionpic.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  color: #ffffff;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-overlay {
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  max-width: 800px;
  text-align: center;
  padding: 20px;
}

.hero-content p {
  font-size: 24px;
  line-height: 1.6;
}

.hero-content p strong {
  font-size: 32px;
}

/* Media query for tablets and below */
@media screen and (max-width: 768px) {
  .hero-content p {
    font-size: 18px;
  }

  .hero-content p strong {
    font-size: 24px;
  }
}
